// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Scalars = require("../../scalars/Scalars.bs.js");
var View__Jsx3 = require("../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AvatarSrcUrl = require("../../utils/AvatarSrcUrl.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Buttons__Jsx3 = require("../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var AgentInfo__Jsx3 = require("../../uikit/reason/atoms/AgentInfo/AgentInfo__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var VerifiedAgentCard__Jsx3 = require("../../uikit/reason/molecules/VerifiedAgentCard/VerifiedAgentCard__Jsx3.bs.js");
var VerifiedAgentCardV5_Agent_graphql = require("../../__generated__/VerifiedAgentCardV5_Agent_graphql.bs.js");
var VerifiedAgentCardV5_Chapter_graphql = require("../../__generated__/VerifiedAgentCardV5_Chapter_graphql.bs.js");
var VerifiedAgentCardV5_sharedProperty_graphql = require("../../__generated__/VerifiedAgentCardV5_sharedProperty_graphql.bs.js");

var convertFragment = VerifiedAgentCardV5_Agent_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, VerifiedAgentCardV5_Agent_graphql.node, convertFragment, fRef);
}

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(VerifiedAgentCardV5_Agent_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, VerifiedAgentCardV5_Agent_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(VerifiedAgentCardV5_Agent_graphql.node, convertFragment, fRef);
}

var AgentFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = VerifiedAgentCardV5_Chapter_graphql.Internal.convertFragment;

function waitForFragmentData$1(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, VerifiedAgentCardV5_Chapter_graphql.node, convertFragment$1, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(VerifiedAgentCardV5_Chapter_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, VerifiedAgentCardV5_Chapter_graphql.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(VerifiedAgentCardV5_Chapter_graphql.node, convertFragment$1, fRef);
}

var ChapterFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  waitForFragmentData: waitForFragmentData$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function VerifiedAgentCardV5(props) {
  var match = use(props.agent);
  var profilePhoto = match.profilePhoto;
  var displayName = match.displayName;
  var chapter = useOpt$1(props.chapter);
  var avatar = profilePhoto !== undefined ? profilePhoto.sidecardUrl : AvatarSrcUrl.place_holder;
  var chapter$1 = Core__Option.flatMap(chapter, (function (c) {
          return c.displayName;
        }));
  return JsxRuntime.jsx(VerifiedAgentCard__Jsx3.make, {
              agentInfo: JsxRuntime.jsx(AgentInfo__Jsx3.make, {
                    avatar: avatar,
                    brokerage: match.brokerage,
                    size: "large",
                    name: displayName,
                    children: Caml_option.some(JsxRuntime.jsx("a", {
                              children: JsxRuntime.jsx(View__Jsx3.make, {
                                    mt: "sm",
                                    children: Caml_option.some(JsxRuntime.jsx(Buttons__Jsx3.SecondaryButton.make, {
                                              size: "small",
                                              icon: "Comment",
                                              children: Caml_option.some("Contact " + displayName)
                                            }))
                                  }),
                              href: "mailto:" + Scalars.EmailAddress.toString(match.emailAddress)
                            }))
                  }),
              title: "Courtesy of",
              bodyText: "Top Agent Network " + Core__Option.getOr(chapter$1, "") + " is a private, online community for the verified top 10% of real estate agents in your area. Members share buyer and seller needs to give their clients a competitive edge in the market."
            });
}

var convertFragment$2 = VerifiedAgentCardV5_sharedProperty_graphql.Internal.convertFragment;

function waitForFragmentData$2(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, VerifiedAgentCardV5_sharedProperty_graphql.node, convertFragment$2, fRef);
}

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(VerifiedAgentCardV5_sharedProperty_graphql.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, VerifiedAgentCardV5_sharedProperty_graphql.node, convertFragment$2);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(VerifiedAgentCardV5_sharedProperty_graphql.node, convertFragment$2, fRef);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  waitForFragmentData: waitForFragmentData$2,
  use: use$2,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

function VerifiedAgentCardV5$FromSharedProperty(props) {
  var match = use$2(props.sharedProperty);
  var chapter = Core__Option.flatMap(match.property, (function (p) {
          return Core__Option.map(p.chapter, (function (c) {
                        return c.fragmentRefs;
                      }));
        }));
  return VerifiedAgentCardV5({
              chapter: chapter,
              agent: match.sender.fragmentRefs
            });
}

var FromSharedProperty = {
  Fragment: Fragment,
  make: VerifiedAgentCardV5$FromSharedProperty
};

var make = VerifiedAgentCardV5;

exports.AgentFragment = AgentFragment;
exports.ChapterFragment = ChapterFragment;
exports.make = make;
exports.FromSharedProperty = FromSharedProperty;
/* Scalars Not a pure module */
